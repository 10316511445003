import { isDefined } from "@clipboard-health/util-ts";
import { IonCardSubtitle, IonIcon, IonRow } from "@ionic/react";
import { VerificationPreferenceDetails } from "@src/app/components/shift/verificationPreferenceDetails";
import { useDefinedEnv } from "@src/app/store/helperHooks/sessionHelpers";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { NfcLocationsDescription } from "@src/appV2/Shifts/NfcHashes/components/NfcLocationsDescription";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { SupportArticleLinks } from "@src/appV2/support/constants";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { openInAppBrowser } from "@src/lib/deprecatedCode";
import { Shift } from "@src/lib/interface";
import { isSameDay } from "date-fns";
import { chevronDown, chevronForward, helpCircleOutline } from "ionicons/icons";
import moment from "moment-timezone";
import { ReactElement, useEffect, useState } from "react";

import { SupportText } from "./support";
import { requiredLunchBreakDuration } from "../../utils/shiftHelper";
import { formatRate } from "../helper";

const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

interface ShiftInstructionsProps {
  shift: Shift;
  shiftStateData?: ShiftStateData;
  hasMissingDocs: boolean;
  isTimeSheetRequired: boolean;
  timeSheetExists: boolean;
  isNewTimeSheetEnabled: boolean;
  isSignatureSubmission: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
}

export function ShiftInstructions({
  shift,
  shiftStateData,
  hasMissingDocs,
  isTimeSheetRequired,
  timeSheetExists,
  isNewTimeSheetEnabled,
  isSignatureSubmission,
  isSolveUnpaidBreaksEnabled,
}: ShiftInstructionsProps): ReactElement {
  const [showCheckInInstructions, setShowCheckInInstructions] = useState(false);
  const [renderInstructions, setRenderInstructions] = useState(false);
  const [isMinimumTimePassed, setIsMinimumTimePassed] = useState(false);
  const [canUploadTimeSheet, setCanUploadTimeSheet] = useState(false);
  const [isShiftStarted, setIsShiftStarted] = useState(false);
  const [isPreShiftStarted, setIsPreShiftStarted] = useState(false);
  const [isPostShiftStarted, setIsPostShiftStarted] = useState(false);
  const [isShiftEnded, setIsShiftEnded] = useState(false);
  const [showShiftInstructions, setShowShiftInstructions] = useState(!shift.clockInOut);

  const disableShiftInstructionsAccordion = !shift.clockInOut;
  const env = useDefinedEnv();
  const { userId } = useDefinedWorker();
  const isShiftOngoing =
    shiftStateData?.metadata.hasShiftStarted && !shiftStateData?.metadata.isShiftOver;
  const isShiftToday = isDefined(shift.start)
    ? isSameDay(new Date(shift.start), new Date())
    : false;
  const isAssigned = shift.agentId === userId;
  const isEarlyClockInEnabled = shiftStateData?.metadata.isEarlyClockInEnabled;

  const timeToConvertToNonIpInMinutes = useCbhFlag(
    CbhFeatureFlag.CONVERT_TO_NONIP_AFTER_X_MINUTES,
    { defaultValue: 60 }
  );

  useEffect(() => {
    const shouldShowInstructions = Boolean(shift.agent || (!shift.agent && !hasMissingDocs));

    setRenderInstructions(shouldShowInstructions);
  }, [shift, hasMissingDocs]);

  useEffect(() => {
    const isPreShiftStartedDiff = moment(shift.start)
      .subtract(env?.minBeforeShiftStart, "minutes")
      .diff(moment());
    setIsPreShiftStarted(isPreShiftStartedDiff <= 0);

    if (isPreShiftStartedDiff > ONE_DAY_IN_MILLIS) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsPreShiftStarted(true);
    }, isPreShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    const isShiftStartedDiff = moment(shift.start).diff(moment());
    setIsShiftStarted(isShiftStartedDiff <= 0);

    if (isShiftStartedDiff > ONE_DAY_IN_MILLIS) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsShiftStarted(true);
    }, isShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    const isPostShiftStartedDiff = moment(shift.start)
      .add(timeToConvertToNonIpInMinutes, "minutes")
      .diff(moment());
    setIsPostShiftStarted(isPostShiftStartedDiff <= 0);

    if (isPostShiftStartedDiff > ONE_DAY_IN_MILLIS) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsPostShiftStarted(true);
    }, isPostShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [timeToConvertToNonIpInMinutes, shift]);

  useEffect(() => {
    const isShiftEndedDiff = moment(shift.end)
      .add(env?.shiftEndGracePeriod, "minutes")
      .diff(moment());
    setIsShiftEnded(isShiftEndedDiff <= 0);

    if (isShiftEndedDiff > ONE_DAY_IN_MILLIS) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsShiftEnded(true);
    }, isShiftEndedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  useEffect(() => {
    if (!renderInstructions) {
      return;
    }

    const timePassed = moment().isAfter(
      moment(shift.start).subtract(env?.minBeforeShiftStart, "minutes")
    );
    setIsMinimumTimePassed(timePassed);

    const hasShiftPassedMinTimeAllowed =
      moment().diff(shift.start, "minutes") > timeToConvertToNonIpInMinutes;

    setCanUploadTimeSheet(hasShiftPassedMinTimeAllowed);

    const showInstructions =
      (!hasShiftPassedMinTimeAllowed && !shift.isInstantPay) ||
      (isShiftOngoing && shift.isInstantPay && !shift.clockInOut) ||
      !isShiftOngoing;

    setShowCheckInInstructions(showInstructions && !shift?.isChangedToNonInstantPay);
  }, [
    shift,
    env,
    isShiftOngoing,
    renderInstructions,
    isMinimumTimePassed,
    canUploadTimeSheet,
    timeToConvertToNonIpInMinutes,
  ]);

  if (!renderInstructions) {
    return <></>;
  }

  const showNfcInstructionsDesc = shift?.nfcTag;

  const onInstantPayInformation = () => {
    openInAppBrowser(SupportArticleLinks.INSTANT_PAY_INFO);
  };

  const { instantPayDetails: { totalAmount = 0 } = {} } = shift;
  const instantPayAmount = totalAmount;
  const remainingAmount = 0;

  const isInstantPayShift = shift?.isInstantPay;

  const todayShiftGreetMessage =
    isShiftToday || isShiftOngoing ? "We hope you have a great shift today!" : "Steps for success!";

  const noNeedToClockMessage = `No need to clock in or clock out for your shift,
  you'll be able to upload a photo of your Timesheet after your shift for verification.`;

  const futureShiftInstruction = {
    title: "Steps for success!",
    message:
      "No need to Clock In or Clock Out for your shift, you’ll just need to upload a photo of your timesheet after your shift for verification! ",
  };

  const timeSheetUploadedDate = shift?.timecard?.files?.length
    ? moment(shift?.timecard?.createdAt).format("dddd, MMM DD")
    : "";

  const [requiresLunchBreak, lunchBreakDuration] = requiredLunchBreakDuration(shift);

  const verificationInstruction =
    isNewTimeSheetEnabled && isSignatureSubmission
      ? "Ask a workplace representative to review your times, and sign off on your device."
      : "Upload a picture of your paper timesheet.";

  const submitInstruction =
    isNewTimeSheetEnabled && isSignatureSubmission
      ? "the signature is submitted."
      : "upload your timesheet.";

  const recordBreakInTimesheetInstruction = isSolveUnpaidBreaksEnabled
    ? " while submitting your timesheet at the end of the shift"
    : "";
  const recordBreakInstruction = requiresLunchBreak
    ? `Please record your break in the app${recordBreakInTimesheetInstruction}.`
    : `If you take a break, please record it in the app${recordBreakInTimesheetInstruction}.`;

  const isTimeSheetEditDisabled = shiftStateData?.metadata.isTimeSheetEditDisabled;

  return (
    <>
      {isInstantPayShift && (
        <>
          {showNfcInstructionsDesc && (
            <>
              <div className="hr" />
              <IonCardSubtitle className="title no-text-transform">
                This workplace uses Clipboard Health NFC Time Clocks
              </IonCardSubtitle>

              <NfcLocationsDescription
                nfcTagRequests={shift?.nfcTagRequests ?? []}
                facilityName={shift?.facility?.name ?? "The workplace"}
              />

              <p>
                <a
                  href="https://support.clipboardhealth.com/hc/en-us/articles/7962761051799-Clocking-in-with-Clipboard-NFC-tags"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tap here for NFC Time Clock instructions.
                </a>
              </p>
            </>
          )}
          <div className="hr" />
          <IonCardSubtitle
            className="title no-text-transform"
            data-testid="shift-instantpay-instructions-toggle"
            onClick={() =>
              setShowShiftInstructions(
                disableShiftInstructionsAccordion ? true : !showShiftInstructions
              )
            }
          >
            Shift Instructions
            <IonIcon
              className="instant-pay-icon"
              icon={showShiftInstructions ? chevronDown : chevronForward}
              hidden={disableShiftInstructionsAccordion}
            />
          </IonCardSubtitle>

          {showShiftInstructions ? (
            <>
              {shift.instantPayDetails?.is100InstantPayEnabled ? (
                <>
                  <p>
                    This is an InstantPay shift.
                    <IonIcon
                      className="instant-pay-icon"
                      aria-label="Instant pay help icon"
                      onClick={onInstantPayInformation}
                      icon={helpCircleOutline}
                    />
                  </p>
                  {isTimeSheetEditDisabled && (
                    <p>
                      {shift.facility?.name ?? "The workplace"} does not allow you to edit your
                      timesheet in-app. If you need to make adjustments to your clock in/out or
                      break times, you will need to submit a paper timesheet. Approval for
                      additional pay may take up to two business days.
                    </p>
                  )}
                  <ol>
                    <li>Clock in when you start working.</li>
                    {!isTimeSheetEditDisabled && (
                      <li data-testid="shift-instructions-break-text">
                        {requiresLunchBreak &&
                          `This workplace requires you to take one ${lunchBreakDuration} unpaid break. `}
                        {recordBreakInstruction}
                      </li>
                    )}
                    <li>Clock out at the end of your shift.</li>
                    <li>{verificationInstruction}</li>
                  </ol>
                  <p data-testid="shift-instructions-submit-text">
                    You&apos;ll be paid up to{" "}
                    <span className="payment-text-color-instantpay">
                      ${formatRate(totalAmount)}
                    </span>{" "}
                    once you finish the shift and {submitInstruction}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Clock In {isEarlyClockInEnabled ? "within 10 minutes of" : "at"} the start of
                    your shift, record your break times, and Clock Out at the end. You&apos;ll
                    receive{" "}
                    <span className="payment-text-color-instantpay">
                      ${formatRate(instantPayAmount)}{" "}
                    </span>
                    for this shift as soon as you&apos;re done! Make sure you upload your timesheet
                    after so your shift can get verified quickly.
                  </p>
                  <p>
                    <span className="payment-text-color">
                      You'll receive the remaining{" "}
                      <span className="payment-text-color">${formatRate(remainingAmount)} </span>
                      once the timesheet is reviewed and the shift is verified.
                    </span>
                  </p>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <div className="hr" />
        </>
      )}
      {shift.facility?.checkInInstructions && showCheckInInstructions && (
        <>
          <IonCardSubtitle className="title no-text-transform">
            Workplace Instructions
          </IonCardSubtitle>
          <p>{shift.facility.checkInInstructions}</p>
        </>
      )}
      {isAssigned && !isInstantPayShift && isShiftToday && !isTimeSheetRequired && (
        <>
          {!shift.isChangedToNonInstantPay && (
            <>
              <IonCardSubtitle className="title no-text-transform">
                {todayShiftGreetMessage}
              </IonCardSubtitle>
              <p>
                <VerificationPreferenceDetails
                  verificationPreference={shift.facility?.verificationPreference}
                  timeSheetExists={timeSheetExists}
                />
              </p>
              {isShiftToday && <SupportText />}
            </>
          )}
        </>
      )}
      {isAssigned &&
        !isInstantPayShift &&
        isShiftToday &&
        isPreShiftStarted &&
        !isShiftOngoing &&
        isTimeSheetRequired &&
        !shift?.isChangedToNonInstantPay && (
          <>
            <IonCardSubtitle className="title no-text-transform">
              {todayShiftGreetMessage}
            </IonCardSubtitle>
            <p>
              {noNeedToClockMessage}
              <VerificationPreferenceDetails
                verificationPreference={shift?.facility?.verificationPreference}
                timeSheetExists={timeSheetExists}
              />
            </p>
            {isShiftToday && <SupportText />}
          </>
        )}
      {isAssigned &&
        !isInstantPayShift &&
        isShiftStarted &&
        !isPostShiftStarted &&
        !canUploadTimeSheet &&
        isTimeSheetRequired &&
        !shift?.isChangedToNonInstantPay && (
          <>
            <IonCardSubtitle className="title no-text-transform">
              {todayShiftGreetMessage}
            </IonCardSubtitle>
            <p>{noNeedToClockMessage}</p>
            <VerificationPreferenceDetails
              verificationPreference={shift?.facility?.verificationPreference}
              timeSheetExists={timeSheetExists}
            />
            <SupportText />
          </>
        )}
      {!isShiftEnded &&
        isAssigned &&
        !isInstantPayShift &&
        isShiftOngoing &&
        isPostShiftStarted &&
        isTimeSheetRequired && (
          <>
            {!shift.isChangedToNonInstantPay && (
              <>
                <IonCardSubtitle className="title no-text-transform">
                  {todayShiftGreetMessage}
                </IonCardSubtitle>
                <p>
                  <VerificationPreferenceDetails
                    verificationPreference={shift?.facility?.verificationPreference}
                    timeSheetExists={timeSheetExists}
                  />
                </p>
              </>
            )}
          </>
        )}
      {isAssigned && isInstantPayShift && (isShiftToday || isShiftOngoing) && (
        <>
          <IonCardSubtitle className="title no-text-transform">
            {todayShiftGreetMessage}
          </IonCardSubtitle>
        </>
      )}
      {!isPreShiftStarted && !isShiftOngoing && !isInstantPayShift && (
        <>
          <IonCardSubtitle className="title no-text-transform">
            {futureShiftInstruction.title}
          </IonCardSubtitle>
          <p>
            {futureShiftInstruction.message}
            <VerificationPreferenceDetails
              verificationPreference={shift?.facility?.verificationPreference}
              timeSheetExists={timeSheetExists}
            />
          </p>
          {<SupportText />}
        </>
      )}
      {timeSheetExists && timeSheetUploadedDate ? (
        <IonRow>
          <p>Timesheet added {timeSheetUploadedDate}</p>
        </IonRow>
      ) : null}
    </>
  );
}
