import { isDefined } from "@clipboard-health/util-ts";
import { DEFAULT_CLOCK_IN_BONUS_POINTS } from "@src/app/attendancePolicy/constants";
import {
  AttendanceScoreProfileResponse,
  LatenessConfigResponse,
} from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { differenceInHours, parseISO } from "date-fns";

export function getPoints(
  attendanceScoreProfile: AttendanceScoreProfileResponse,
  shiftStartTime: string
): {
  workPoints: number;
  cancelPointsNow: number;
  onTimePoints: number;
  latenessPoints: LatenessConfigResponse;
} {
  const hoursBeforeShift = differenceInHours(parseISO(shiftStartTime), new Date(), {
    roundingMethod: "ceil",
  });
  const cancelPointsPolicy = attendanceScoreProfile.policy?.cancelShift?.find(({ leadTime }) => {
    const [minHours, maxHours] = leadTime;
    return (
      (isDefined(minHours) ? minHours < hoursBeforeShift : true) &&
      (isDefined(maxHours) ? maxHours >= hoursBeforeShift : true)
    );
  });
  const workPoints = attendanceScoreProfile.policy?.workShift?.points ?? 0;
  const cancelPointsNow = Math.abs(cancelPointsPolicy?.points ?? 0);
  const onTimePoints =
    attendanceScoreProfile.policy?.shiftClockInConfig?.points ?? DEFAULT_CLOCK_IN_BONUS_POINTS;
  const latenessPoints = attendanceScoreProfile.policy?.latenessConfig ?? [];

  return {
    workPoints,
    cancelPointsNow,
    onTimePoints,
    latenessPoints,
  };
}
