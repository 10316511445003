import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { shuffle } from "lodash";
import { useMemo } from "react";

export const defaultQuestionDetails = {
  firstYesOrNoQuestion: "Did this professional complete their assigned tasks today when possible?",
  mainQuestion: {
    description: "How did this professional do after arriving at your facility today?",
    primaryOptions: [
      "Excellent attitude",
      "Great with patients",
      "Could put in some extra effort",
      "Professionalism could be better",
    ],
    otherOptions: ["Other", "Skip"],
  },
};

export function useRevampWorkplaceSigningFlow() {
  const { enabled, firstYesOrNoQuestion, mainQuestion } = useCbhFlag(
    CbhFeatureFlag.ROLLOUT_FEEDBACK_FOR_QUALITY_EXPERIMENT,
    {
      defaultValue: {
        enabled: false,
      },
    }
  );

  const mainQuestionOptions = useMemo(() => {
    const defaultMainQuestion = defaultQuestionDetails.mainQuestion;
    const {
      primaryOptions = defaultMainQuestion.primaryOptions,
      otherOptions = defaultMainQuestion.otherOptions,
    } = mainQuestion ?? {};
    return [...shuffle(primaryOptions), ...otherOptions];
  }, [mainQuestion]);

  return {
    useRevampedWorkplaceSignoutIsEnabled: enabled,
    firstYesOrNoQuestion: firstYesOrNoQuestion ?? defaultQuestionDetails.firstYesOrNoQuestion,
    mainQuestion: {
      description: mainQuestion?.description ?? defaultQuestionDetails.mainQuestion.description,
      options: mainQuestionOptions,
    },
  };
}
